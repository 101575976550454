body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: BlinkMacSystemFont,-apple-system,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dropdown-menu {
  right: 0px;
  left: unset;
}

.btn:disabled {
  background: #999;
  border: #999;
  color: #555;
  cursor: not-allowed;
}

.react-datepicker__input-container input {
  width: 140px;
  text-align: center;
  padding: .375rem .75rem;
  margin: 0px 10px;
  border-radius: .25rem;
  cursor: pointer;
}

.react-datepicker-popper {
  left: -30px !important;
}

.datePicker {
  width: 100%;
}

.datePicker input {
  width: 100% !important;
  margin-left: 0;
  margin-right: 0;
  text-align: left;
  height: calc(2em + .75rem);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  box-shadow: inset 0 1px 2px rgba(10,10,10,0.1);
  border: 1px solid #ced4da;
}

.navbar-light .navbar-nav .nav-link {
  margin: 0 10px;
}

#site {
  min-height: calc(100vh - 41px);
}

#paypal-button-container {
  width: 350px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.table-bordered td, .table-bordered th {
  font-size: 1rem;
}

#topUpAdvertiserDropdown ~ .dropdown-menu {
  width: 100%;
  height: 150px;
}

[data-cy~="navDropdown"]:hover, [data-cy~="navDropdown"]:focus {
  outline: none !important;
  border-color: #F8F9FA !important;
  background-color: #F8F9FA !important;
  box-shadow: none !important;
}

@media (min-width: 992px) {
  .modal-lg, .modal-xl {
    max-width: 75%;
  }
}

@media (max-width: 767px) {
  body {
    font-size: 12px;
  }
  .react-datepicker__input-container input {
    width: 100px;
  }
}